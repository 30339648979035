import React from 'react'
import styled from 'styled-components'
import {colors, fontfamily} from '../lib/variables'
import {Link} from 'gatsby'

const StyledBasePortableText = styled.div`
  max-width: 640px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4{
    text-align: left;
    font-family: ${fontfamily.jaRounded};
    margin-bottom: 2.4rem;
    padding-top: 3.6rem;
    color: ${colors.navy};
    position: relative;
    padding-bottom: 0.8rem;
    font-weight: 700;
    & :after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.yellow};
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  h2{
    font-size: 2.0rem;
  }
  h3{
    font-size: 1.8rem;
    padding-top: 2.4rem;
    margin-bottom: 1.6rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }
  h4{
    font-size: 1.6rem;
    padding-top: 1.8rem;
    margin-bottom: 1.4rem;
    padding-bottom: 0rem;
    & :after{
      display: none;
    }
  }

  ul,
  ol{
    font-size: 1.6rem;
    list-style-position: outside;
    margin: 0 0 0 2.6rem;
    padding: 0;
    line-height: 1.75;
  }
  li{
    margin: 0 0 2.4rem 0;
  }
  strong,
  bold{
    color: ${colors.navy};
  }
 `

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-weight: 400;
  font-size: 1.8rem;
`

const Heading = props => {
  return (
    <>
      <StyledBasePortableText>
        <h1>NY育英サマースクール&キャンプ2020第一ターム参加規約</h1>
        <h3>（1）健康・安全面</h3>
        <p>
          ・オンライン保育・授業中の怪我や病気、事故は学園は責任を負いかねます。<br />
          ご家庭でお子様の健康状態を観察し、体調が悪い場合は十分に休息を取らせてください。
        </p>
        <h3>（2）ご家族の参加</h3>
        <p>
          ・幼児部は常に保護者がこどもの側にいて子どものサポートをしてください。<br />
        </p>
        <p>
          ・参加は本人のみとし、保護者を除き兄弟姉妹の参加はご遠慮ください。兄弟姉妹が参加する場合は参加する全員分をそれぞれお申し込みください。<br />
        </p>

        <h3>（3）振替規定</h3>
        <p>
          <b>クラスのキャンセル・遅刻連絡</b><br />
          最少催行人数に満たなかった場合や、講師側の都合によるクラスのキャンセル時は、事前にメールにて保護者へお知らせします。
        </p>
        <p>
          <b>技術的な不具合</b><br />
          オンライン保育・授業を受けるにあたり、各家庭のインターネットや使用する電子機器の使用環境を整えてください。参加者側の接続環境の不備により参加できなかったまたは一部を見逃した場合の振替や延長は出来かねますので、予めご了承ください。ただし、講師側の不備によりキャンセルや一時中断が生じた場合は、当日または後日振替や時間の調整を致します。
        </p>

        <h3>（4）プライバシー規定</h3>
        <p>
          <b>個人情報の取り扱いについて</b><br />
          本申し込みフォームで収集された個人情報は、ニューヨーク育英オンラインサマースクール＆キャンプを提供する目的のみで使用され、学園の権利、名誉などを保護するために必要であると学園が判断した場合を除き、事前の同意なく第三者に開示することはありません。申込者の個人情報は、本学園の各種キャンプのお知らせや関連したアンケートを送信する場合にも使用されます。それらの配信を希望しない場合は、summerschool2020.nyikuei@gmail.comまでお知らせください。
        </p>
        <p>
          <b>広告用の写真・映像使用について</b><br />
          ニューヨーク育英オンラインサマースクール＆キャンプ期間中撮影された写真、ビデオなどは本学園のパンフレット、学園カレンダー、学園ビデオ、HPのブログなど広報に掲載されることがありますので、ご了承ください。不都合がある場合は、Meetの画面に表示される氏名の後に、写真・映像使用の不可のサインとして「NP」を入れてください。（例: Shigeru KonoNP）。広告に使用するための撮影をする場合は、事前にメールにて保護者へ告知いたしますが、「NP」サインが入っているお子様が含まれる場合は広告用の撮影を行いません。また、広告用として使用する写真や映像に表示されるお子様の氏名は、見えないよう学園側で加工します。
        </p>
        <p>
          <b>記録用録画の同意</b><br />
          広告用写真・映像使用の同意の有無に関わらず、全ての授業・保育を講師側がスクリーン上で録画いたします。これらは学園側が記録として録画し、授業や保育の指導に役立てるためだけのみに使用し、第三者への開示は行いません。
        </p>
        <p>
          <b>配信動画及びコンテンツの家庭での取り扱いについて</b><br />
          配信される映像、画像はご家庭での閲覧に留めて下さい。オンライン保育・授業の様子を、スクリーンショットやレコーディング等で撮影・複製したり、SNSやYouTubeなどインターネット上へ公開することは堅く禁じます。
        </p>

        <h3>（5）返金規定</h3>
        <p>
          申し込み後、各参加週の2週間前の月曜日の正午（NY時間）まで、メール（summerschool2020.nyikuei@gmail.com）にてキャンセルを申し出た場合は、参加費の50%を返金致します。それ以降にキャンセルを申し出た場合や、欠席や未参加のプログラムに対して返金・料金の調整はございません。
          5週間を申し込んだ場合の50パーセントの返金が適用されるのは6/19の正午までとなり、一部の週をキャンセルすることはできませんので予めご了承ください。
        </p>
      </StyledBasePortableText>
    </>
  )
}
export default Heading
